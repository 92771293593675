import { useSearchParams } from '@solidjs/router';
import { createStore } from 'solid-js/store';
import { createEffect } from 'solid-js';
import z from 'zod';
import dayjs from '@troon/dayjs';
import type { SetStoreFunction, Store } from 'solid-js/store';

export function zodGetDefaults<T extends z.ZodRawShape>(
	schema: z.ZodObject<T>,
): {
	[K in keyof T as T[K] extends z.ZodDefault<z.ZodTypeAny> ? K : never]: z.infer<T[K]>;
} {
	return Object.fromEntries(
		Object.entries(schema.shape)
			.map(([k, v]) => [k, v instanceof z.ZodDefault ? v._def.defaultValue() : undefined])
			.filter(([, v]) => v !== undefined),
	);
}

export const toSearchSchema = z.record(
	z.string(),
	z.unknown().transform((input) => {
		if (input instanceof Date) {
			return dayjs(input).format('YYYY-MM-DD');
		}
		if (Array.isArray(input)) {
			return input.map((s) => s.toString());
		}
		if (input === null || typeof input === 'undefined') {
			return undefined;
		}
		return input.toString();
	}),
);

export function createSearchStore<S extends z.ZodObject<z.ZodRawShape>>(schema: S) {
	const [searchParams, setSearchParams] = useSearchParams();
	const defaults = zodGetDefaults(schema);
	const [store, setStore] = createStore(schema.safeParse({ ...defaults, ...searchParams }).data ?? defaults);

	createEffect(() => {
		const data = toSearchSchema.safeParse({ ...store }).data!;
		setSearchParams(data, { replace: true });
	});

	return [store, setStore] as [SearchStore<S>, SetSearchStore<S>];
}

export type SearchStore<S extends z.ZodObject<z.ZodRawShape> = z.ZodObject<z.ZodRawShape>> = Store<z.infer<S>>;
export type SetSearchStore<S extends z.ZodObject<z.ZodRawShape> = z.ZodObject<z.ZodRawShape>> = SetStoreFunction<
	z.infer<S>
>;

export const coerceBoolean = () =>
	z.preprocess((val) => {
		if (typeof val === 'string') {
			if (['1', 'true'].includes(val.toLowerCase())) return true;
			if (['0', 'false'].includes(val.toLowerCase())) return false;
		}
		return val;
	}, z.coerce.boolean());

export const coerceDate = (def?: Date) =>
	z
		.preprocess((val: unknown) => {
			if (val) {
				const date = dayjs(`${val}T00:00:00`);

				if (date.isValid()) {
					return date.toDate();
				}
			}
			return def;
		}, z.coerce.date())
		.default(def);
